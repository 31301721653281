:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-top-edit {
  .siteView {
    padding: 0 30px;
    iframe {
      border: none;
      border-radius: 20px;
      overflow: hidden;
      pointer-events: none;
    }
  }

  .textForm {
    ul {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      li {
        display: grid;
        grid-template-columns: 8em auto;
        align-items: center;

        &.heroheader {
          display: grid;
          grid-template-columns: 1fr;
          gap: 10px;
        }

        &.radio-form,
        &.checkbox-form {
          display: grid;
          grid-template-columns: 1fr;
          p.ttl {
            grid-column: 1 / span 2;
            margin-bottom: 1em;
          }
          .radio,
          .checkbox {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
            gap: 10px;
            input[type="radio"],
            input[type="checkbox"] {
              display: none;
            }
            label {
              display: grid;
              grid-template-columns: 18px auto;
              align-items: center;
              gap: 0.5em;
              cursor: pointer;
              &:before {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                border: 1px solid #fff;
                border-radius: 999px;
              }
              p {
                font-size: 12px;
              }
            }
            input[type="radio"]:checked + label {
              font-weight: bold;
              p {
                color: #3493c6;
              }
              &:before {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                border: 6px solid #3493c6;
                border-radius: 999px;
                box-sizing: border-box;
                background-color: #fff;
              }
            }
          }
          .checkbox {
            label {
              position: relative;
              i {
                display: none;
              }
              &:before {
                border-radius: 3px;
              }
            }
            input[type="checkbox"]:checked + label {
              &:before {
                background-color: #3493c6;
              }
              i {
                display: block;
                position: absolute;
                top: 55%;
                left: 0.25em;
                translate: 0 -50%;
              }
              p {
                color: #9fddfe;
              }
            }
          }
        }
        &.priceAreaWrap {
          grid-template-columns: 1fr;
          gap: 10px;
          .form-label {
          }
          .priceArea {
            display: grid;
            grid-template-columns: auto auto auto auto;
            gap: 1em;
            input {
              border-right: 1px solid #ffffff50;
            }
            .controlButton {
            }
          }
          .addBtn {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 01em;
            border: 1px solid #fff;
            padding: 0.25em 1em;
            border-radius: 5px;
            margin-left: auto;
            cursor: pointer;
            i {
              font-size: 18px;
            }
            &:hover {
              color: var(--bgc_2);
              background-color: #ffffff50;
              p {
                color: #fff;
                font-weight: bold;
              }
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .upload {
    display: grid;
    grid-template-columns: 50% auto;
    gap: 10px;
    .upload-area {
      padding: 0 10px;
      border: 2px dashed #fff;
      border-radius: 10px;
      display: flex;
      grid-template-columns: 20% 80%;
      align-items: center;
      justify-content: center;
      gap: 1em;
      height: 15vh;
      &:hover {
        background-color: #ffffff30;
      }
      svg {
        font-size: 50px;
        color: #fff;
      }
    }
    .imgSelectBtn {
      border-radius: 10px;
      color: #202131;
    }
  }
  .imgChecked {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    figure {
      position: relative;
      overflow: hidden;
      background-color: #fff;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      video {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      .remove-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        color: #b73535;
        font-size: 20px;
        background-color: #fff;
        border-radius: 999px;
        cursor: pointer;
      }
    }
  }

  .ov-inner {
    width: calc(100% - 100px);
    height: calc(100svh - 100px);
    background-color: var(--bgc_2);
    border-radius: 20px;
    overflow-y: hidden;
    padding: 30px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto 250px;
    gap: 10px;
    &.video-ov {
      grid-template-columns: auto;
    }
    .imgList {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.75em;
      overflow-y: auto;
      .ov-ttl {
        border-bottom: 1px solid #fff;
        padding-bottom: 0.5em;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
        gap: 5px;
        li {
          position: relative;
          overflow: hidden;
          cursor: pointer;
          &:before {
            display: block;
            content: "";
            padding-top: 100%;
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            height: 100%;
            width: auto;
          }
          video {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            height: 100%;
            width: auto;
          }
          &:after {
            display: block;
            content: "";
            width: 18px;
            height: 18px;
            border: 2px solid #fff;
            box-sizing: border-box;
            position: absolute;
            top: 4px;
            right: 4px;
            border-radius: 10px;
            background-color: #ffffffaa;
          }
          &.on {
            &:after {
              display: block;
              content: "";
              width: 18px;
              height: 18px;
              border: 7px solid #08537c;
              background-color: #fff;
              box-sizing: border-box;
              position: absolute;
              top: 4px;
              right: 4px;
              border-radius: 10px;
            }
          }
        }
      }
    }
    .imgPreview {
      border-left: 1px solid #ffffff10;
      padding-left: 10px;
      overflow: hidden;
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      align-content: flex-start;
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        height: 100%;
        width: auto;
      }
      button {
        width: calc(100% - 10px);
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--active_2);
        padding: 1em;
        border-radius: 5px;
      }
    }
    .movieList {
      overflow-y: auto;
      video {
        width: 50%;
      }
    }
  }
}
