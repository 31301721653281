:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;
  --active_3: #ecff82;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-studio-list,
.website-studio-edit,
.website-instructor-list,
.website-instructor-edit,
.website-visual,
.website-lesson-edit,
.website-qa,
.website-faq-list,
.website-faq-edit,
.website-news-list,
.website-news-edit,
.website-review,
.website-campaign-list,
.website-campaign-edit,
.website-top-edit,
.website-about-edit,
.website-price-edit {
  --bgc_1: #38384b;
  --bgc_2: #202131;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

* {
  box-sizing: border-box;
  //   スクロールバー
  &::-webkit-scrollbar {
    background: initial;
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00000050;
    border-radius: 999px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #00000080;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
a {
  color: #fff;
  text-decoration: none;
}
a,
h1,
li,
p,
span,
input,
button {
  color: #fff;
  font-family: "A1ゴシック R", "Noto Sans JP", "Hiragino Kaku Gothic ProN",
    sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  &.b {
    font-family: "見出ゴMB1", sans-serif;
    font-weight: 600;
  }
}

button {
  border: none;
  font-family: "Midashi Go MB1", sans-serif;
  padding: 0.25em;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.submit {
    background-color: var(--active_2);
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 0.25em 3em;
  }
}

button.type-1 {
  border-radius: 999px;
  font-size: 12px;
  width: 100%;
  color: #fff;
  padding: 0.5em 0.5em;
}

button.type-2 {
  border-radius: 10px;
  font-size: 12px;
  width: 100%;
  color: #fff;
  padding: 0.5em 0.5em;
  background-color: initial;
  border: 2px solid #fff;
}

button.type-3 {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
figure {
  margin: 0;
  padding: 0;
}

.imgCenter {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    height: auto;
  }
}

body {
  background: linear-gradient(30deg, var(--bgc_1) 40%, #151515);
  &.website-studio-list,
  &.website-studio-edit,
  &.website-instructor-list,
  &.website-instructor-edit,
  &.website-lesson-edit,
  &.website-qa,
  &.website-faq-list,
  &.website-faq-edit,
  &.website-news-list,
  &.website-news-edit,
  &.website-review,
  &.website-campaign-list,
  &.website-campaign-edit,
  &.website-top-edit,
  &.website-about-edit,
  &.website-price-edit {
    background: linear-gradient(30deg, var(--bgc_1) 40%, #222268);
  }
  //   ベースのレイアウト
  .wrap {
    display: grid;
    grid-template-columns:
      30px 30px calc((100% - 60px) / 10) calc((100% - 60px) / 10)
      calc((100% - 60px) / 10) calc((100% - 60px) / 10) calc((100% - 60px) / 10)
      calc((100% - 60px) / 10) calc((100% - 60px) / 10) calc((100% - 60px) / 10)
      calc((100% - 60px) / 10);
    grid-template-rows: calc(95svh - 80px) 80px;
    gap: 0 var(--gap_1);
    transition-duration: 0.75s;
    transition-timing-function: cubic-bezier(0, 0.78, 0.49, 0.97);
    padding: 10px 10px;
    box-sizing: border-box;
    &.open {
      grid-template-columns:
        100px 100px calc((100% - 200px) / 10) calc((100% - 200px) / 10)
        calc((100% - 200px) / 10) calc((100% - 200px) / 10) calc(
          (100% - 200px) / 10
        )
        calc((100% - 200px) / 10) calc((100% - 200px) / 10) calc(
          (100% - 200px) / 10
        )
        calc((100% - 200px) / 10);
    }
    .content-grid {
      background-color: var(--bgc_2);
      padding: 20px 10px;
    }
    .grid-1 {
      grid-column: span 2;
      grid-row: 1/2;
      border-radius: 20px;
      overflow-y: auto;
    }
    .grid-2 {
      grid-row: 1/3;
      border-radius: 20px;
      position: relative;
      overflow-y: auto;
      &.layout-type-1 {
        grid-column: 3/10;
      }
      &.layout-type-2 {
        grid-column: 3/8;
      }
      &.layout-type-3 {
        grid-column: 3/6;
      }
      &.layout-type-4 {
        grid-column: 3/12;
      }
      &:before {
        content: "";
        width: 20px;
        height: 50px;
        background-color: #fff;
        position: absolute;
        z-index: 1;
        bottom: 78px;
        left: 0px;
        border-bottom-left-radius: 40px;
        box-shadow: -9px 8px var(--bgc_1);
        background: transparent;
      }
      &.addButton {
        // &:after {
        //   content: "";

        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   border-bottom-left-radius: 20px;

        //   width: 200px;
        //   height: 60px;

        //   background: linear-gradient(#222263, #222261);
        // }
        .add {
          z-index: 999;
          position: absolute;
          width: 200px;
          height: 60px;
          background-color: #282861;
          right: 0;
          top: 0;
          border-bottom-left-radius: 50px;

          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          &:before,
          &:after {
            content: "";
            display: block;

            width: 30px;
            height: 30px;
            position: absolute;

            // background-color: #fff;
            background: transparent;
          }
          &:before {
            top: 0;
            right: 100%;
            border-top-right-radius: 20px;
            box-shadow: 10px -10px 0 #282861;
          }
          &:after {
            top: 100%;
            right: 0;
            border-top-right-radius: 20px;
            box-shadow: 10px -10px 0 #282861;
          }

          button {
            background-color: var(--bgc_2);
            position: relative;
            z-index: 9999;
            padding: 1.25em 0;
            width: 170px;
            font-weight: 900;
            letter-spacing: 1px;
            &.type-grad {
              background: linear-gradient(
                100deg,
                #296f89,
                var(--active_2)
              ) !important;
            }
          }
        }
      }
    }
    .grid-3 {
      grid-row: 1/3;
      border-radius: 20px;
      overflow-y: auto;
      &.layout-type-1 {
        grid-column: 10/12;
      }
      &.layout-type-2 {
        grid-column: 8/12;
      }
      &.layout-type-3 {
        grid-column: 6/12;
      }
      &.layout-type-4 {
        grid-column: 12/12;
      }

      &.edit {
        position: relative;
        &:before,
        &:after {
          content: "";
          display: block;
          z-index: 999;
        }
        &:before {
          content: "";
          display: block;
          width: 50px;
          height: 35px;
          border-top-right-radius: 50px;
          box-shadow: 10px -10px 0 #171717;
          position: absolute;
          background-color: transparent;
          right: 0px;
          top: 85px;
        }
        &:after {
          content: "";
          display: block;

          width: 35px;
          height: 50px;
          border-top-right-radius: 50px;

          box-shadow: 10px -10px 0 #171717;

          background-color: transparent;
          position: absolute;
          right: 85px;
          top: 0;
        }
        .closeBtn {
          z-index: 999;
          position: absolute;
          top: 0;
          right: 0;
          width: 85px;
          height: 85px;
          border: 10px solid #171717;
          background-color: #171717;
          border-radius: 999px;
          box-shadow: 40px 0px 0px #171717;
          &:hover {
            opacity: 1;
            &:after {
              background-color: #363636;
            }
            &:vi {
            }
          }
          i {
            font-size: 40px;
            z-index: 999;
            position: absolute;
            top: -5px;
            left: 26px;
            margin: 0;
            padding: 0;
            color: #fff;
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: -10px;
            right: -10px;
            translate: 50% -50%;
            width: 155px;
            height: 155px;
            background-color: #171717;
            border-radius: 80px;
            z-index: 0;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            width: 70px;
            height: 70px;
            translate: 10px -10px;
            background-color: var(--bgc_2);
            border-radius: 999px;
            transition-duration: var(--duration);
            transition-timing-function: var(--cubic-bezier);
          }
        }
      }
      .form {
        // padding: 0.75em 0.75em;
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
    .menu-btn {
      grid-column: 1/4;
      grid-row: 2/3;
      border-radius: 999px;
      border: var(--gap_1) solid var(--bgc_1);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
      cursor: pointer;
      &:before {
        content: "";
        width: 20px;
        height: 50px;
        position: absolute;
        z-index: 1;
        bottom: -12px;
        right: -30px;
        border-bottom-left-radius: 40px;
        box-shadow: -10px 10px var(--bgc_1);
        background: transparent;
      }
      &:after {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 1;
        bottom: -5px;
        right: -2px;
        border-bottom-right-radius: 22px;
        box-shadow: 9px 8px var(--bgc_1);
        background: transparent;
      }
      p {
        font-family: "Tapir Pro Regular", sans-serif;
        font-size: 18px;
        color: #fff;
      }
      span {
        &.leftLine,
        &.rightLine {
          content: "";
          display: block;
          height: 1px;
          background-color: #fff;
          transition-duration: var(--duration);
          transition-timing-function: var(--cubic-bezier);
        }
        &.leftLine {
          width: 0;
          opacity: 0;
        }
        &.rightLine {
          width: 2em;
          opacity: 1;
        }
      }
    }

    // メニュー　開いている時
    .sideMenu {
      padding: 10px;
      display: grid;
      row-gap: 1.25em;
      place-items: baseline;
      place-content: baseline;
      justify-content: center;
      h1 {
        white-space: nowrap;
        display: flex;
        padding: 0.8em 0 1.2em 0;
        border-bottom: 1px solid #fff;
        span {
          translate: 0 0 !important;
          i {
            font-style: normal;
            font-size: 18px;
          }
        }
      }
      a {
        display: flex;
        justify-content: center;
        gap: 1em;
        position: relative;
        width: 100%;
        &:first-child {
          margin-bottom: 10px;
        }
        span {
          translate: 0em 0;
          transition-duration: var(--duration);
          transition-timing-function: var(--cubic-bezier);
        }
        p {
          position: absolute;
          top: 50%;
          left: 0em;
          translate: 0% -50%;
          margin-left: -1.5em;

          display: none;
          opacity: 0;
          white-space: nowrap;
          //   transition-duration: var(--duration);
          opacity: 0;
          transition-timing-function: var(--cubic-bezier);
          transition: opacity 0.5s, display 0.5s allow-discrete, width 0.5s;
          overflow: hidden;
        }

        &.active,
        &:hover {
          span {
            svg {
              path {
                fill: var(--active_1);
              }
            }
          }
          p {
            color: var(--active_1);
          }
        }
      }
    }
    &.close {
      h1 {
        span {
          display: none;
          opacity: 0;
          transition: opacity 0.5s, display 0.5s allow-discrete;
          width: auto;
          overflow: hidden;
          &:nth-child(1) {
            display: flex;
            opacity: 1;
          }
          &:nth-child(n + 2) {
            width: 0;
          }
        }
      }
      .sideMenu {
        span {
          text-align: center;
          font-size: 18px;
        }
      }
    }
    // メニュー　開いている時
    &.open {
      .sideMenu {
        a {
          span {
            translate: -5em 0;
          }
          p {
            opacity: 1;
            display: block;
            translate: 3em -50%;
            @starting-style {
              opacity: 0;
            }
          }
        }
      }
      .menu-btn {
        span {
          &.leftLine,
          &.rightLine {
            content: "";
            display: block;
            height: 1px;
            background-color: #fff;
          }
          &.leftLine {
            width: 2em;
            opacity: 1;
          }
          &.rightLine {
            opacity: 0;
            width: 0em;
          }
        }
      }
    }
  }

  // メッセージ
  .message {
    display: grid;
    grid-template-columns: auto 10em;
    align-items: center;
    background-color: #aaaaaa;
    &.success {
      background-color: #4d4935;
      padding: 1em;
      button {
        border: none;
        background: #938211;
      }
    }
  }

  // ライブラリ（小）
  .libraryWindow {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 34px auto auto;
    row-gap: 30px;
    overflow: hidden;
    height: 100%;
    &.notab {
      grid-template-rows: 40vh 55vh;
    }
    li {
      cursor: pointer;
      background-color: #fff;
      &:hover {
        opacity: 0.5;
      }
    }
    img {
      height: auto;
      width: 100%;
    }
    // タブ
    .tabBtn_type1 {
      width: fit-content;
      display: flex;
      align-items: center;
      background-color: var(--bgc_1);
      border-radius: 999px;
      position: relative;
      margin: 0 auto;
      li {
        width: fit-content;
        padding: 0.5em 1em !important;
        white-space: nowrap;
        cursor: pointer;
        background-color: transparent;
        &.active {
          position: relative;
          border-radius: 999px;
          background-color: var(--active_2);
        }
        &:hover {
          color: var(--active_2);
        }
      }
    }
    .tabPanel {
      padding: 20px 20px 0 20px;
    }
    .checked {
      display: grid;
      grid-template-columns: 40% auto;
      align-content: flex-start;
      gap: 5px;
      row-gap: 0.5em;
      .nowchecked {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 2em auto;
        row-gap: 0.5em;
      }
      .checkedList {
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
        grid-template-rows: 2em auto;
        align-content: start;
        column-gap: 10px;
        row-gap: 0.5em;
        p {
          grid-column: 1 / span 4;
        }
        li {
          position: relative;
          overflow: hidden;
          &:before {
            content: "";
            display: block;
            padding-top: 100%;
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
          }
          i {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 20px;
            color: var(--active_2);
            text-shadow: 0 0 5px #ffffff;
          }
        }
      }
      button {
        grid-column: 1 / span 2;
        padding: 1em;
      }
    }
    .libraryList {
      overflow: auto;
      p.ttl {
        text-align: center;
        font-size: 18px;
        position: sticky;
        top: 0;
        z-index: 99;
        padding: 0.5em 0;
        margin: 0 auto;
        backdrop-filter: blur(5px);
      }
      p.hashtag {
        border-bottom: 1px solid #fff;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, 100px);
        margin-bottom: 3em;
        gap: 10px;
        li {
          position: relative;
          overflow: hidden;
          &:before {
            content: "";
            display: block;
            padding-top: 100%;
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
          }
          i {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 20px;
            color: var(--active_2);
            text-shadow: 0 0 5px #ffffff;
          }
        }
      }
    }
  }

  // パンクズ
  .breadcrumb {
    margin-bottom: 30px;
    ul {
      display: flex;
      width: fit-content;
      border-radius: 999px;
      overflow: hidden;
      li {
        font-size: 13px;
        padding: 0.5em 2.5em 0.5em 2em;
        width: fit-content;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
        background-color: #2a2a2a; // 半透明の背景色
        position: relative;
        margin-left: -1em;
        box-shadow: -1px 0 0 #1a1a1a;
        &:first-child {
          padding: 0.5em 2.5em 0.5em 2em;
          margin-left: 0;
        }
        &:last-child {
          background-color: var(--active_2);
          background: linear-gradient(to left, #1464a5, #2e3192);
          padding: 0.5em 1.5em;
        }
      }
    }
  }

  // インプットタグ
  label.form {
    padding: 0.5em;
    border-radius: 10px;
    background-color: var(--bgc_1);
    input,
    textarea {
      color: #fff;
      background-color: var(--bgc_1);
      border: none;
      font-size: 13px;
      outline: none;
      line-height: 1.75;
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
    textarea {
      width: 100%;
      height: 100%;
      min-height: 10em;
    }

    &.icon {
      display: flex;
      align-items: center;
      gap: 0.5em;
      i {
        color: #fff;
      }
      input {
        width: 100%;
        border: none;
        font-size: 13px;
        padding: 0.5em;
      }
    }

    &.switch {
      width: 60px;
      position: relative;
      border-radius: 999px;
      cursor: pointer;
      input[type="radio"] {
        display: none;
      }
      &:before,
      &:after {
        content: "";
        display: block;
      }
      &:after {
        width: 20px;
        height: 20px;
        border-radius: 999px;
        background-color: #fff;

        transition-duration: 0.25s;
        box-shadow: 0 0 5px #000;
      }
      &.active {
        background-color: var(--active_3);
        &:after {
          margin-left: 25px;
        }
      }
    }
  }

  // 表
  .List {
    overflow: auto;
    max-height: 70vh;
    .gridList {
      display: grid;
      grid-template-columns: 4em auto auto auto auto auto 12em auto;
      margin-top: 1em;
      .ttl {
        display: grid;
        grid-template-columns: subgrid;
        gap: 0.5em 0;
        grid-column: span 8;
        position: sticky;
        top: 0;
        background: linear-gradient(var(--bgc_2) 80%, transparent);
        z-index: 1;
        li {
          font-size: 12px;
          color: #ffffff70;
        }
      }
      li {
        font-size: 13px;
        padding: 1em 1em 1em 0;
      }
      ul.listContents {
        display: grid;
        grid-template-columns: subgrid;
        gap: 1px 0;
        grid-column: span 8;
        border-bottom: 1px solid #ffffff50;
        position: relative;
        li {
          cursor: pointer;
        }
        &:hover {
          background-color: #009cff1a;
          &:before {
            content: "▲";
            position: absolute;
            top: 50%;
            right: 5px;
            color: #fff;
            font-size: 10px;
            translate: 0 -50%;
            rotate: 90deg;
          }
        }
      }
    }
  }

  // タブ
  .tabArea {
    display: grid;
    row-gap: 10px;
    .tabBtn_type1 {
      width: fit-content;
      display: flex;
      align-items: center;
      background-color: var(--bgc_1);
      border-radius: 999px;
      position: relative;
      margin: 0 auto;
      li {
        width: fit-content;
        padding: 0.5em 1em !important;
        white-space: nowrap;
        cursor: pointer;
        background-color: transparent;
        &.active {
          position: relative;
          border-radius: 999px;
          background-color: var(--active_2);
        }
        &:hover {
          color: var(--active_2);
        }
      }
    }
    .tab-content {
      display: grid;
      gap: 20px 0;
    }
  }
}

.overlay {
  background-color: #00000080;
  z-index: 999999;
  width: 100%;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  .overlay-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    svg {
      margin: 0 auto;
      width: 35px;
      height: 35px;
      color: #fff;
    }
    p {
      font-size: 18px;
    }
  }
}

.login {
  height: 100svh;
  .loginWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    h2 {
      width: 100%;
      color: #fff;
      text-align: center;
    }
    .formWrap {
      width: calc(100% - 10px);
      max-width: 400px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1em;

      .inputLabel {
        p {
          margin-bottom: 0.5em;
        }
        input {
          width: 100%;
          background-color: initial;
          border: 1px solid #fff;
          border-radius: 5px;
          font-size: clamp(16px, 2vw, 18px);
          padding: 0.5em;
        }
      }
      button {
        padding: 0.75em 0;
        background-color: #3e6bff;
        font-size: clamp(14px, 2vw, 16px);
        margin-top: 1em;
        border-radius: 5px;
      }
    }
  }
  .message {
    padding: 0.5em;
    border: 2px solid #c13e3e;
    background-color: initial;
    border-radius: 5px;
    p {
      color: #ff3d3d;
      font-weight: bold;
    }
  }
}
