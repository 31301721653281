:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-studio-edit {
  .addButton {
    .add {
    }
  }

  //
  .studioEditList {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 4em 3em;
    overflow-x: hidden;
    p.ttl {
      margin-top: 2em;
    }
    ul.form1 {
      grid-column: span 6;
      display: grid;
      grid-template-columns: subgrid;
      gap: 1em 1em;
      align-items: flex-start;
      li {
        align-items: flex-start;
        grid-column: span 3;
        display: grid;
        grid-template-columns: 5em auto;
        gap: 1em;
        span {
          white-space: nowrap;
        }
        input {
          width: 90%;
        }
      }
    }
    ul.imgList {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 125px));
      grid-column: span 6;
      gap: 20px;
      li {
        position: relative;
        padding-top: 100%;
        background-color: #fff;
        overflow: hidden;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
          height: 100%;
          width: auto;
        }
      }
    }
    ul.btnList {
      grid-column: span 6;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
      column-gap: 10px;
    }
    .message {
      grid-column: span 6;
    }
  }
}
