:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-visual {
  h2 {
    color: #fff;
    text-align: center;
    font-size: 17px;
  }
  h4 {
    color: #fff;
    font-size: 15px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }
  .section {
    margin-bottom: 50px;
    ul.thumbnail {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 90px));
      justify-content: flex-start;
      align-content: flex-start;
      gap: 10px;
      max-height: 30vh;
      overflow-y: auto;

      li {
        position: relative;
        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }
        figure {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff05;
          overflow: hidden;
          cursor: pointer;
          &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            background-color: #ffffff50;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
          }
          img {
            width: auto;
            height: 100%;
            position: relative;
            z-index: 1;
            opacity: 0.8;
          }
          .off {
            opacity: 0;
          }
          &:hover {
            img {
              opacity: 1;
            }
            .off {
              opacity: 1;
            }
          }
          // お気に入り
          .favorite {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 999;
            font-size: 20px;
            &.on {
              color: #eaff00;
            }
          }
          // ローディング
          .loading {
            animation: spin 1s linear infinite;
            font-size: 20px;
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
          //
          .test {
            position: absolute;
            top: 50%;
          }
        }
      }
    }
  }
  .upload-area {
    border: 2px dashed #fff;
    border-radius: 10px;
    display: flex;
    grid-template-columns: 20% 80%;
    align-items: center;
    justify-content: center;
    gap: 1em;
    height: 25vh;
    &:hover {
      background-color: #ffffff30;
    }
    svg {
      font-size: 50px;
      color: #fff;
    }
  }
  .preview-area {
    overflow: hidden;
    padding: 10px 0;
    box-sizing: border-box;
    figure {
      height: 30vh;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .cursel {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 10px;
      li {
        opacity: 0.5;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
