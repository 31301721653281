.website-lesson-edit {
  .addButton {
    .add {
    }
  }

  //
  .grid-2 {
    overflow: hidden;
  }
  .lessonEdit {
    display: grid;
    grid-template-columns: 100%;
    align-content: flex-start;
    gap: 2em 0;
    height: 100%;
    overflow: hidden;
    // 表の時間を設定
    .setting {
      .timeSetting {
        display: flex;
        align-items: center;
        gap: 0.5em;
        .date {
          position: relative;
          input {
            padding: 0.5em;
            border-radius: 10px;
            background-color: var(--bgc_1);
            border: none;
          }
          input[type="time"]::-webkit-calendar-picker-indicator {
            // 時計マークの色を変更
            filter: invert(1); // 例: 色を白に変更するフィルター
          }

          i {
            position: absolute;
            right: 0.5em;
            top: 50%;
            translate: 0 -50%;
            color: #fff;
          }
        }
      }
    }

    // レッスンのベースとなるグリット
    .lessonBaseGrid {
      display: grid;
      grid-template-columns:
        5em calc(100% / 8) calc(100% / 8) calc(100% / 8)
        calc(100% / 8) calc(100% / 8) calc(100% / 8) calc(100% / 8);
      overflow-y: auto;
      padding-bottom: 20vh;
      ul {
        display: grid;
        grid-template-rows: subgrid;
        position: relative;
        z-index: 0;
        li {
          border: 1px dotted #ffffff20;
          min-height: 12px;
        }
      }

      // 曜日
      .week {
        position: relative;
        z-index: 1;
        grid-column: span 1;
        li {
          border-right: 1px solid #ffffff50;
          &:first-child {
            background-color: #ffffff50;
            padding: 0.25em 0;
            text-align: center;
            min-height: initial;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:active {
            background-color: rgba(243, 255, 17, 0.565);
          }
          &:nth-child(2n + 1) {
            border-bottom: 1px solid #ffffff50;
          }
        }
        &.mon {
          border-left: 1px solid #ffffff50;
          grid-column: 2/3;
        }
        &.tue {
          grid-column: 3/4;
        }
        &.wed {
          grid-column: 4/5;
        }
        &.thu {
          grid-column: 5/6;
        }
        &.fri {
          grid-column: 6/7;
        }
        &.sat {
          grid-column: 7/8;
        }
        &.sun {
          grid-column: 8/9;
        }
      }

      // 時間のテキスト
      .timeText {
        display: grid;
        position: relative;
        z-index: 1;
        li {
          border: none;
          position: relative;
          &.text {
            grid-row: span 12;
          }
          span {
            position: absolute;
            top: 0;
            right: 1em;
            translate: 0 -50%;
          }
        }
      }

      //レッスン
      .lesson {
        display: grid;
        grid-template-rows: subgrid;
        grid-template-columns: subgrid;
        grid-column: 2 / 9;
        position: relative;
        z-index: 0;
        li {
          padding: 5px;
          position: relative;
          i {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #ffffff80;
            color: #000000;
            border-radius: 999px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5em;
            height: 1.5em;
            cursor: pointer;
            transition-duration: 0.25s;
            &:hover {
              scale: 1.3;
            }
          }
          &.mon {
            grid-column: 1/2;
          }
          &.tue {
            grid-column: 2/3;
          }
          &.wed {
            grid-column: 3/4;
          }
          &.thu {
            grid-column: 4/5;
          }
          &.fri {
            grid-column: 5/6;
          }
          &.sat {
            grid-column: 6/7;
          }
          &.sun {
            grid-column: 7/8;
          }
          .lessonData {
            display: grid;
            align-items: center;
            align-content: center;
            gap: 2px;
            border-radius: 10px;
            height: 100%;
            padding: 5px 2px;
            p,
            input,
            select {
              width: 100%;
              background-color: #ffffffe5;
              border: none;
              color: #000;
              font-weight: 600;
              padding: 0.25em 5px;
              border-radius: 5px;
              font-size: 10px;
            }
            p {
              color: #fff;
              font-size: 14px;
              background-color: transparent;
              line-height: 1;
              text-align: center;
            }
          }
          &.notDone {
            .lessonData {
              border: none;
              background-color: #abb351af;
              border: 2px dotted #ffffff;
            }
          }
          &.done {
            .lessonData {
              border: 2px solid #3c3cab;
              background-color: #3c3cabaf;
            }
          }
        }
      }
    }
  }
}
