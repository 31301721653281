:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}
.home {
  .grid-3 {
    padding: 0 !important;
  }
  .studenSingle {
    width: 100%;
    figure {
      position: relative;
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
      }
      img {
        position: absolute;
        inset: 0;
        width: auto;
        height: 100%;
      }
    }
    .tabBtn {
      margin: 30px auto 0 auto;
      width: fit-content;
      display: flex;
      align-items: center;
      background-color: var(--bgc_1);
      border-radius: 999px;
      position: relative;
      li {
        width: fit-content;
        padding: 0.5em 1em;
        white-space: nowrap;
        cursor: pointer;
        &.active {
          position: relative;
          border-radius: 999px;
          background-color: var(--active_2);
        }
        &:hover {
          color: var(--active_2);
        }
      }
    }
    .tabPanel {
      padding: 20px 20px 0 20px;
      .detail,
      .plan,
      .pay {
        ul {
          display: grid;
          grid-template-columns: 40% auto;
          gap: 1.5em 1em;
          li {
            font-size: 12px;
            &.full {
              grid-column: 1/3;
            }
          }
          li:nth-child(2n-1) {
            color: #ffffff50;
          }
        }
      }
      .plan {
        .planLesson {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1em 0;
          p,
          button {
            font-size: 12px;
          }
          p {
            color: #ffffff50;
          }
          button {
            color: #fff;
            border-radius: 3px;
            border: 2px solid #fff;
            background-color: initial;
            padding: 0.75em 0.25em;
          }
        }
      }
      .pay {
        .paymentListWrap {
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 1em 0;
          margin-top: 25px;
          overflow: auto;
          height: 49vh;
          .paymentList {
            display: grid;
            grid-template-columns: subgrid;
            grid-column: span 3;
            align-items: center;
            button {
              border-radius: 999px;
              font-size: 12px;
              width: 100%;
              color: #fff;
              padding: 0.5em 0.5em;
            }
            &.paid {
              button {
                background-color: var(--active_2);
              }
            }
            &.unpaid {
              button {
                background-color: var(--unactive);
              }
            }
          }
        }
      }
    }
  }
}
