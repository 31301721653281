:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}
.management-remove-customer-list {
  .gridList {
    grid-template-columns: 4em auto auto auto auto auto !important;
    .ttl,
    .listContents {
      grid-column: span 6 !important;
      gap: 0 1.5em !important;
      li {
        width: 100%;
        position: relative;
        line-height: 1.75;
      }
    }
  }
}
