:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}
.management-send-mail {
  .grid-3 {
    background-color: initial !important;
    padding: 0 !important;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 20px;
    align-content: baseline;
    .calendar-content,
    .template-content {
      padding: 20px 10px;
      border-radius: 20px;
      background-color: var(--bgc_2);
    }
    .calendar-content {
      .month {
        text-align: center;
        border-bottom: 1px solid #fff;
        padding-bottom: 3px;
        margin-bottom: 1em;
        span {
          cursor: pointer;
        }
      }
      .dateList {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 1em;
        span {
          text-align: center;
          position: relative;
          z-index: 1;
          cursor: pointer;
          &.active:after,
          &:hover:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            width: 2em;
            height: 2em;
            border-radius: 999px;
            z-index: 0;
            mix-blend-mode: overlay;
          }
          &.today {
            color: #ffff43;
          }
          &.active:after {
            background-color: var(--active_2);
          }
          &:hover:after {
            background-color: #ffffff20;
          }
        }
      }
    }
    .template-content {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 10px;
      overflow: auto;
      button {
        .delete {
          display: block;
          float: inline-end;
          color: var(--unactive);
          font-weight: 800;
        }
      }
    }
  }
  .mailcontent {
    .mailContent-grid {
      display: grid;
      grid-template-columns: 5em auto;
      margin-bottom: 20px;
    }
    .recipientWrap {
      li {
        font-size: 12px;
        padding: 0.15em;
        border: 1px solid #fff;
        border-radius: 5px;
        text-align: center;
        &.btn2 {
          border-radius: 999px;
        }
        &.on {
          background-color: var(--active_2);
        }
      }
      .recipient-content {
        p {
          font-size: 12px;
        }
        ul {
          display: grid;
          gap: 0.5em;
          grid-template-columns: repeat(auto-fit, minmax(2em, 6em));
          border-bottom: 1px solid #ffffff50;
          padding-bottom: 0.75em;
          margin-bottom: 0.75em;
          // &:last-child {
          //   border: none;
          // }
        }
      }
    }
    .ttlWrap {
      p {
        padding-top: 0.75em;
      }
      input {
        width: 100%;
      }
    }
    .btn-wrap {
      text-align: right;
      button {
        margin-left: 5px;
      }
    }
  }
}
