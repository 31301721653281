:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-news-edit {
  .addButton {
    .add {
    }
  }

  //
  .newsEditList {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 4em 3em;
    overflow-x: hidden;
    p.ttl {
      margin-top: 2em;
    }
    ul.form1 {
      grid-column: span 6;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
      gap: 1em 1em;
      align-items: flex-start;
      li {
        align-items: flex-start;
        grid-column: span 3;
        display: grid;
        grid-template-columns: 5em auto;
        gap: 2em;
        span {
          text-align: right;
          white-space: nowrap;
        }
        input {
          width: 90%;
        }
      }
      .hashTag {
        display: grid;
        label {
          display: flex;
          justify-content: baseline;
          align-items: center;
          gap: 0.5em;
          flex-wrap: wrap;
          p {
            background-color: #ffffff50;
            border-radius: 999px;
            padding: 0.25em 0.5em;
            width: fit-content;
            display: flex;
            justify-content: baseline;
            align-items: center;
            gap: 5px;
            span {
              font-size: 11px;
            }
          }
          i {
            width: 1em;
            height: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: #000;
            border-radius: 999px;
          }
          .addHashtag {
            position: relative;
            input {
              width: 10em;
            }
            ul {
              position: absolute;
              top: 100%;
              left: 0;
              width: 130px;
              max-height: 200px;
              background-color: #ffffff;
              border-radius: 5px;
              display: grid;
              grid-template-columns: 1fr;
              gap: 5px;
              padding: 0.5em 0.25em;
              z-index: 1;
              li {
                color: #777;
                display: block;
                font-size: 13px;
                border-radius: 5px;
                padding: 0 0.5em;
                cursor: pointer;
                &:hover {
                  background-color: #c0cdff;
                  color: #2d41c0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    ul.imgList {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 125px));
      grid-column: span 6;
      gap: 20px;
      li {
        position: relative;
        padding-top: 100%;
        background-color: #fff;
        overflow: hidden;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
          height: 100%;
          width: auto;
        }
      }
    }
    ul.btnList {
      grid-column: span 6;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
      column-gap: 10px;
    }
    .message {
      grid-column: span 6;
    }
  }
}
