:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}
.management-free-customer-list {
  .gridList {
    grid-template-columns: 4em 6em 3em auto auto auto auto auto auto auto auto auto auto !important;
    .ttl,
    .listContents {
      grid-column: span 12 !important;
      li {
        width: 100%;
        white-space: nowrap;
        position: relative;
      }
    }
  }

  .grid-3 {
    height: 100%;
    overflow: auto;
  }
  .studioList {
    p {
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }
    ul {
      margin: 15px 0 20px 0;
      display: grid;
      gap: 10px;
      grid-template-columns: auto auto auto;
      li {
        button {
          background-color: initial;
          &.on {
            background-color: var(--active_2);
          }
          &.active {
            border: 1px solid #eefa63;
            color: #eefa63;
          }
        }
      }
    }
  }
  .FreecustomerEdit {
    .contentTtl {
      width: 100%;
      border-bottom: 1px solid #ffffff50;
      padding: 0.5em 0 0.5em 0;
      margin-bottom: 1em;
    }
    ul {
      display: grid;
      grid-template-columns: 5em auto;
      &.dateList,
      &.delete {
        grid-template-columns: 1fr;
      }
      &.delete {
      }
      li {
        font-size: 12px;
        margin-bottom: 1em;
        &.deleteBtn {
          button {
            background-color: var(--unactive);
            border: none;
            padding: 0.75em;
          }
        }
        &.on {
          button {
            border-width: 3px;
            border-color: var(--active_1);
            font-weight: 600;
            color: var(--active_1);
            position: relative;
            i {
              position: absolute;
              top: 50%;
              right: 1em;
              translate: 0 -50%;
            }
          }
        }
        &.submit {
          button {
            background-color: var(--active_2);
            border: none;
            font-weight: 900;
            padding: 0.75em;
          }
        }
      }
      li:nth-child(2n - 1) {
        color: #ffffff50;
        font-size: 12px;
      }
    }
  }
}
