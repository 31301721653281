:root {
  --bgc_1: #303030;
  --bgc_2: #1a1a1a;

  --gap_1: 10px;

  --active_1: #76bbff;
  --active_2: #08537c;

  --unactive: #773232;

  --duration: 0.75s;
  --cubic-bezier: cubic-bezier(0, 0.78, 0.49, 0.97);
}

.website-faq-list {
  .faqListcontent {
    .List {
      .gridList {
        grid-template-columns: 25em 25em auto auto 3em;
        .listContents {
          align-items: center;
          padding: 0 0.5em;
          i {
            display: none;
            font-size: 15px;
            margin-right: 0.5em;
          }
          li.delete {
            display: none;
            text-align: center;
            grid-column: 8/9;
          }
          &:hover {
            i {
              display: inline-block;
            }
            li.delete {
              display: block;
              font-size: 17px;
              color: var(--unactive);
              font-weight: 800;
            }
          }
          .published {
            display: flex;
            align-items: center;
            i {
              font-size: 12px;
              display: block;
              color: var(--active_3);
            }
            span {
              color: var(--active_3);
            }
          }
        }
      }
    }
  }
  .faqEditList {
    grid-template-columns: 5em auto !important;
    row-gap: 0.75em;
    align-items: center;
    li {
      padding: 0 !important;
      label {
        display: block;
        padding: 0.75em 0.75em;
        width: calc(100% - 1.5em);
        overflow: hidden;
        input {
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: 600;
        }
      }
    }
    li.submit {
      grid-column: span 2;
      button {
        padding: 0.75em 0;
      }
    }
    .category-area {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5em;
      .checkbox {
        display: flex;
        align-items: center;
        gap: 0.5em;
        padding: 0;
        // .input-area {
        //   // display: grid;
        //   // label {

        //   //   gap: 1em 0.5em;
        //   //   padding: 0;
        //   //   font-size: 14px;
        //   // }
        // }
      }
    }
  }
}
